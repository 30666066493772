// Mobile Hero Slider

// We extract the color of the main slider for the background of one of its columns on mobile devices

  function darkenHexColor(hex, percent) {
    if (!hex || !/^#([0-9A-Fa-f]{3}){1,2}$/.test(hex)) {
      console.error("Invalid hex color:", hex);
      return "#000000";
    }

    hex = hex.replace("#", "");
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    r = Math.max(0, Math.floor(r * (1 - percent)));
    g = Math.max(0, Math.floor(g * (1 - percent)));
    b = Math.max(0, Math.floor(b * (1 - percent)));

    r = r.toString(16).padStart(2, '0');
    g = g.toString(16).padStart(2, '0');
    b = b.toString(16).padStart(2, '0');

    return "#" + r + g + b;
  }

  function applyDarkenedColor(slideElement, childSelector, darknessPercent) {
    if (!slideElement) return;

    const computedStyle = window.getComputedStyle(slideElement);
    let bgColor = computedStyle.backgroundColor;

    if (!bgColor || bgColor === "" || bgColor === "rgba(0, 0, 0, 0)" || bgColor === "transparent") return;

    if (bgColor.startsWith("rgb")) {
      let rgb = bgColor.substring(bgColor.indexOf("(") + 1, bgColor.lastIndexOf(")")).split(",");
      let r = parseInt(rgb[0].trim());
      let g = parseInt(rgb[1].trim());
      let b = parseInt(rgb[2].trim());
      bgColor = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    } else if (!bgColor.startsWith("#")) return;

    const darkenedColor = darkenHexColor(bgColor, darknessPercent);

    const childElements = slideElement.querySelectorAll(childSelector);
    childElements.forEach(child => {
      child.style.backgroundColor = darkenedColor;
    });
  }

  apply_solid_bg = function applyDarkenedColorToAllSlides(splideRoot, childSelector, darknessPercent) {
    const slides = splideRoot.querySelectorAll('.splide__slide:not(.is-clone)', '.block.home');
    slides.forEach(slide => {
      applyDarkenedColor(slide, childSelector, darknessPercent);
    });
  }
