// Mobile Hero Slider
// Calling and building the main home slider - splidejs

document.addEventListener("DOMContentLoaded", function() {

  const splides = document.querySelectorAll('.splide');
  
  splides.forEach(splideElement => {
      const splide = new Splide(splideElement, {
          type: 'loop',
          perPage: 1,
          pagination: true,
          cover: true,
          autoHeight: true, // Automatically adjust height to visible slide's content.
          breakpoints: {
            768: {  // Apply specific configurations for mobile.
              autoHeight: true,  // Ensure height adapts on smaller screens.
            }
          },
          arrowPath: 'm13.292 12l-4.6-4.6l.708-.708L14.708 12L9.4 17.308l-.708-.708z',
          classes: {
            arrows: 'tbs-splide__arrows',
            arrow: 'tbs-splide__arrow',
            prev: 'tbs-splide__arrow--prev',
            next: 'tbs-splide__arrow--next',
          },
      });

      splide.mount();

      // Applying the new darken background color on mobile devices

        if (window.matchMedia("(max-width: 768px)").matches) {
          splide.on('visible moved', function () {
            apply_solid_bg(splide.root, ".wp-block-columns > .wp-block-column", 0.3);
            // apply_gradient(splide.root, ".wp-block-columns > .wp-block-column", 0.2);
          });
        }

      splide.refresh(); // Call refresh AFTER the gradients or solid bg color are applied

  });

});
